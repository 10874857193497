import { Annotation } from "../../annotator/interfaces/annotation";
import { getFlatEntityListFromGroupBlocks } from "../../configMap/utils/entityUtils";
import { ConfigMap, GroupBlockData } from "../../configMap";
import { DocumentCategoryAnnotation } from "../../configMap/interfaces/category";
import { AnnotationItem } from "../interfaces";

export const getEntityIds = (groupBlocks?: Array<GroupBlockData>) => {
  if (!groupBlocks) {
    return [];
  }

  return getFlatEntityListFromGroupBlocks(groupBlocks).map(
    (entity) => entity.entityType.id
  );
};

export const getCategorizationIds = (
  groupBlocks?: Array<GroupBlockData>,
  configMap?: ConfigMap | undefined
) => {
  if (!groupBlocks && !configMap) {
    return [];
  }

  const groupBlockCategorizations =
    groupBlocks?.flatMap((gb) =>
      gb.categorizationGroupBlocks.map((cg) => cg.categorization.id)
    ) ?? [];
  const documentCategorizations =
    configMap?.categorizationConfigMaps?.map((ccm) => ccm.categorization.id) ??
    [];
  return [...groupBlockCategorizations, ...documentCategorizations];
};

export const filterNewAnnotations = (
  mappedAnnotations: Array<Annotation>,
  newEntityIds: Array<string>,
  oldEntityIds: Array<string>
) => {
  const onlyNewEntityIds = newEntityIds.filter(
    (entityId) => !oldEntityIds.includes(entityId)
  );
  return mappedAnnotations.filter((annotation) =>
    onlyNewEntityIds.includes(annotation.entity.id)
  );
};

export const filterNewCategoryAnnotations = (
  mappedAnnotations: Array<DocumentCategoryAnnotation> | undefined,
  newCategorizationIds: Array<string>,
  oldCategorizationIds: Array<string>,
  groupBlocks?: Array<GroupBlockData>
) => {
  if (!mappedAnnotations?.length) {
    return [];
  }

  const onlyNewEntityIds = newCategorizationIds.filter(
    (entityId) => !oldCategorizationIds.includes(entityId)
  );
  const categoryAnnotations = mappedAnnotations.filter((annotation) =>
    onlyNewEntityIds.includes(annotation.categorizationId)
  );

  return updateDcaWithNewGroupBlockId([...categoryAnnotations], groupBlocks);
};

export const updateAnnotationsWithNewGroupBlockId = (
  annotations: Array<Annotation>,
  groupBlocks?: Array<GroupBlockData>
): Array<Annotation> => {
  return [...annotations].map((annotation) => {
    const groupBlock = groupBlocks?.find((gb) =>
      gb.groupBlockEntityTypes
        .map((gbet) => gbet.entityType.id)
        .includes(annotation.entity.id)
    );
    return {
      ...annotation,
      groupBlockId: groupBlock?.id,
    };
  });
};

export const updateDcaWithNewGroupBlockId = (
  annotations: Array<DocumentCategoryAnnotation>,
  groupBlocks?: Array<GroupBlockData>
): Array<DocumentCategoryAnnotation> => {
  return [...annotations].map((annotation) => {
    const groupBlock = groupBlocks?.find((gb) =>
      gb.categorizationGroupBlocks
        .map((cgb) => cgb.categorization.id)
        .includes(annotation.categorizationId)
    );
    return {
      ...annotation,
      groupBlockId: groupBlock?.id ?? annotation.groupBlockId,
    };
  });
};

export const mergeNewAnnotations = (
  updatedAnnotations: Array<AnnotationItem>,
  newAnnotations: Array<AnnotationItem>
): Array<AnnotationItem> => {
  for (const newAnnotation of newAnnotations) {
    const isEntity = newAnnotation.isEntity;

    if (
      (isEntity && !newAnnotation.multipleGroupBlocks) ||
      (!isEntity && !newAnnotation.groupBlockId)
    ) {
      updatedAnnotations.push(newAnnotation);
    } else {
      const existingRowAnnotations = updatedAnnotations.filter(
        (annotation) =>
          ((isEntity && annotation.multipleGroupBlocks) ||
            (!isEntity && annotation.groupBlockId)) &&
          annotation.originalIndex === newAnnotation.originalIndex &&
          annotation.groupBlockId === newAnnotation.groupBlockId
      );

      const annotation = transformAnnotationIndex(
        newAnnotation,
        updatedAnnotations,
        existingRowAnnotations
      );
      updatedAnnotations.push(annotation);
    }
  }
  return updatedAnnotations;
};

const transformAnnotationIndex = (
  newAnnotation: AnnotationItem,
  updatedAnnotations: Array<AnnotationItem>,
  existingRowAnnotations: Array<AnnotationItem>
): AnnotationItem => {
  if (existingRowAnnotations.length) {
    return {
      ...newAnnotation,
      index: existingRowAnnotations[0].index,
      originalIndex: existingRowAnnotations[0].originalIndex,
    };
  } else {
    const sameRowAnnotations = getSameIndexAnnotations(
      updatedAnnotations,
      newAnnotation.originalIndex,
      newAnnotation.groupBlockId
    );

    return calculateAnnotationIndex(
      sameRowAnnotations,
      updatedAnnotations,
      newAnnotation
    );
  }
};

const getSameIndexAnnotations = (
  annotations: Array<AnnotationItem>,
  originalIndex: number | undefined,
  groupBlockId: string | undefined
) => {
  return annotations.filter(
    (annotation) =>
      annotation.groupBlockId === groupBlockId &&
      annotation.originalIndex === originalIndex
  );
};

export const calculateAnnotationIndex = (
  sameRowAnnotations: Array<AnnotationItem>,
  updatedAnnotations: Array<AnnotationItem>,
  newAnnotation: AnnotationItem
) => {
  const isIndexOccupied = sameRowAnnotations !== undefined;

  if (isIndexOccupied) {
    const indexes = [...updatedAnnotations]
      .filter(
        (annotation) => annotation.groupBlockId === newAnnotation.groupBlockId
      )
      .map((annotation) => annotation.index!);
    const highestIndex = indexes.length ? Math.max(...indexes) : 0;

    return {
      ...newAnnotation,
      index: highestIndex + 1,
    };
  }

  return {
    ...newAnnotation,
    index: newAnnotation.index!,
  };
};

export const mapBackToEntityAnnotations = (
  annotations: Array<AnnotationItem>
) => {
  const entityAnnotations: Array<Annotation> = annotations
    .filter((a) => a.isEntity)
    .map((item) => ({
      ...item,
      id: item.id!,
      entity: item.entity!,
      page: item.page!,
      isByUser: item.isByUser!,
      multipleGroupBlocks: item.multipleGroupBlocks!,
    }));
  return entityAnnotations;
};

export const mapBackToDocumentCategoryAnnotations = (
  annotations: Array<AnnotationItem>
) => {
  const dcaAnnotations: Array<DocumentCategoryAnnotation> = annotations
    .filter((a) => !a.isEntity)
    .map((item) => ({
      ...item,
      documentId: item.documentId!,
      categorizationId: item.categorizationId!,
      categoryId: item.categoryId!,
    }));
  return dcaAnnotations;
};

export const findOriginalIndex = (
  annotations: Array<Annotation>,
  documentCategoryAnnotations: Array<DocumentCategoryAnnotation>,
  groupBlockId: string | undefined,
  index: number | undefined
) => {
  const originalIndexAnnotation = annotations.find(
    (a) =>
      a.originalIndex && a.groupBlockId === groupBlockId && a.index === index
  )?.originalIndex;
  const originalIndexDca = documentCategoryAnnotations.find(
    (dca) =>
      dca.originalIndex &&
      dca.groupBlockId === groupBlockId &&
      dca.index === index
  )?.originalIndex;
  return originalIndexAnnotation ?? originalIndexDca;
};
