import React, { FC } from "react";
import { useAppSelector } from "../../../../app";
import tokensForPageSelector from "../../../selectors/tokensForPageSelector";

type Props = {
  page: number;
  pdfWidth: number;
  pdfHeight: number;
};

const BoundingBoxLayer: FC<Props> = ({ page, pdfWidth, pdfHeight }) => {
  // @ts-ignore
  const debugEnabled: boolean = window.klassifDebug;

  const tokens = useAppSelector((state) =>
    tokensForPageSelector(state, { pageNumber: page })
  );

  if (!debugEnabled) {
    return null;
  }

  return (
    <div
      className="bounding-box-layer__container"
      style={{
        width: `${pdfWidth}px`,
        height: `${pdfHeight}px`,
        top: 0,
        left: 0,
        position: "absolute",
      }}
    >
      {tokens.map((token) => {
        const { top, left, width, height } = token;
        return (
          <span
            key={token.dataI}
            style={{
              position: "absolute",
              top: `${top}px`,
              left: `${left}px`,
              width: `${width}px`,
              height: `${height}px`,
              border: `1px solid red`,
            }}
          />
        );
      })}
    </div>
  );
};

export default BoundingBoxLayer;
