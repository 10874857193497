import { User, UserSettings } from "../interfaces/interfaces";
import {
  AUTH0_USER_ID_PREFIX,
  initialsRegex,
  USER_LOCALSTORAGE_NAME,
} from "../constants";
import { UserDataResponse } from "../interfaces/responseModels";

export const defaultSettings: UserSettings = {
  approveOpenNextDocument: false,
  holdOpenNextDocument: false,
  rejectOpenNextDocument: false,
  enableAnnotationMode: false,
  enableTransitionView: false,
  enableAnimations: true,
  hideNonRequiredEntities: false,
  hideAnnotationBoxes: false,
  saveFilters: false,
  saveSorter: false,
  language: "en",
  overviewSorter: "",
  archiveSorter: "",
  overviewFilters: [],
  archiveFilters: [],
  overviewOrderColumns: [],
  archiveOrderColumns: [],
  showOverviewTour: false,
  showDetailTour: false,
};

const setValue = <T>(obj: T, key: keyof T, value: T[keyof T]) => {
  obj[key] = value;
};

export const getUserFromLocalStorage = (): User | null => {
  const user = localStorage.getItem(USER_LOCALSTORAGE_NAME);
  return user ? JSON.parse(user) : null;
};

export const parseSettings = (responseData: UserSettings): UserSettings => {
  if (responseData) {
    Object.keys(defaultSettings).forEach((key: string) => {
      if (!Object.prototype.hasOwnProperty.call(responseData, key)) {
        const keyType = key as keyof UserSettings;
        setValue(responseData, keyType, defaultSettings[keyType]);
      }
    });

    return responseData;
  }

  return defaultSettings;
};

export const getInitialsFromName = (
  firstName: string,
  lastName: string
): string => {
  // @ts-ignore
  let initials = [...`${firstName} ${lastName}`.matchAll(initialsRegex)] || [];
  return (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
};

export const parseJwt = (token: string): any =>
  JSON.parse(atob(token.split(".")[1]));

export const removeUnknownInputIdsFromFilters = (
  inputPermissions: Array<string>,
  filters: Array<string>
) => {
  if (!filters?.length || !inputPermissions?.length) {
    return [];
  }

  const inputPermissionsLowerCase = inputPermissions.map((permission) =>
    permission.toLowerCase()
  );
  const inputIdFilter = filters.find((filter) => filter.includes("input.id"));

  if (!inputIdFilter) {
    return filters;
  }

  const ids = inputIdFilter.replace("input.id=", "").split("+");
  const allowedInputIds = ids.filter((id) =>
    inputPermissionsLowerCase.includes(id.toLowerCase())
  );

  if (!allowedInputIds.length) {
    return filters.filter((filter) => !filter.includes("input.id"));
  }

  const newInputIdFilter = `input.id=${allowedInputIds.join("+")}`;
  return filters.map((filter) =>
    filter.includes("input.id") ? newInputIdFilter : filter
  );
};

export const getDefaultAvatarForUser = (userData: UserDataResponse): string => {
  if (userData.userProfile.id.includes(AUTH0_USER_ID_PREFIX)) {
    return userData.picture;
  }

  return `${userData.userProfile.firstName
    .charAt(0)
    .toUpperCase()}${userData.userProfile.lastName.charAt(0).toUpperCase()}`;
};
