import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OptionType } from "../interfaces/classifier";
import {
  fetchClassifierOptions,
  fetchClassifierOptionsSearch,
} from "../utils/classifier";
import {
  changeActiveButton,
  changeActiveEntity,
  changeAnnotating,
  changeEditing,
  changeHasBeenEdited,
} from "../../app/store/appSlice";
import {
  CategorizationSetType,
  CategorizationWithConfig,
  DocumentCategoryAnnotation,
  ICategorization,
} from "../../configMap/interfaces/category";
import selectDocumentCategoryAnnotationId from "../selectors/selectDocumentCategoryAnnotationId";
import {
  removeDocumentCategoryAnnotation,
  removeIncompleteCategorization,
  updateDocumentCategoryAnnotation,
} from "../store/metadataSlice";
import useEntityAnnotations from "./useEntityAnnotations";
import { buildSearchQuery } from "../../common/utilities/requests";
import { Status } from "../../common/status/status";
import { InputStatus } from "antd/es/_util/statusUtils";
import { useTranslation } from "react-i18next";
import useParentCategorization from "./useParentCategorization";
import { debounce } from "lodash";
import { findOriginalIndex } from "../../documentTypes/utils/synchronizeAnnotationHelper";
import useCategorizationEvents from "./useCategorizationEvents";

const getInitialOptions = (
  categorization: ICategorization
): Array<OptionType> => {
  if (
    categorization.setType === CategorizationSetType.DYNAMIC ||
    categorization.setType === CategorizationSetType.DERIVED
  ) {
    return [];
  }

  return categorization.categories.map(
    (cat): OptionType => ({
      key: cat.id,
      value: cat.id,
      label: cat.visibleValue,
      visibleValue: cat.visibleValue,
    })
  );
};

const useCategorization = (
  categorizationWithConfig: CategorizationWithConfig,
  index: number,
  groupBlockId?: string
) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { categorization, required } = categorizationWithConfig;
  const { id, search, setType } = categorization;
  const initialOptions = getInitialOptions(categorization);

  const dispatch = useAppDispatch();

  const editing = useAppSelector((state) => state.appReducer.isEditing);
  const document = useAppSelector(
    (state) => state.documentReducer.activeDocument
  );
  const activeDocumentSet = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const { documentCategoryAnnotations } = useAppSelector(
    (state) => state.metadataReducer.categorizationState
  );
  const annotations = useAppSelector(
    (state) => state.annotationReducer.tableControlAnnotations
  );

  const categoryAnnotation = useAppSelector((state) =>
    selectDocumentCategoryAnnotationId(state, {
      documentId: document?.id,
      categorizationId: id,
      groupBlockId: groupBlockId,
      index: index,
    })
  );

  const {
    derivedCategorizationOptions,
    loadingCategorizationIds,
    incompleteCategorizations,
  } = useAppSelector((state) => state.metadataReducer);

  const normalizeEntityAnnotations = useEntityAnnotations();

  const {
    isCategorizationDisabledByParent,
    disabledParentTooltip,
    attributeFilter,
  } = useParentCategorization(
    categoryAnnotation,
    categorization,
    document?.id,
    groupBlockId,
    index,
    activeDocumentSet?.input?.attribute
  );

  const [options, setOptions] = useState<Array<OptionType>>(initialOptions);
  const [disableTyping, setDisableTyping] = useState(false);

  const isCategorizationLoading = useMemo(() => {
    return loadingCategorizationIds.includes(categorization.id);
  }, [loadingCategorizationIds, categorization.id]);

  const tabId = useMemo(() => {
    if (groupBlockId) {
      return `categorization-${id}-${groupBlockId}-${index}`;
    }

    return `categorization-${id}-${index}`;
  }, [id, index, groupBlockId]);

  const selectedCategory = useMemo(() => {
    if (!categoryAnnotation) {
      return null;
    }

    const isFixedType = setType === CategorizationSetType.FIXED;
    if (!isFixedType) {
      return {
        value: categoryAnnotation.categoryValue,
        visibleValue: categoryAnnotation.categoryVisibleValue,
      };
    }

    const found = categorization.categories.find(
      (c) => c.id === categoryAnnotation.categoryId
    );

    if (found) {
      return found;
    }

    return null;
  }, [categoryAnnotation, categorization, setType]);

  const { callEvents } = useCategorizationEvents();

  const removeIncompleteCategorizationInRedux = useCallback(
    (categorizationId: string, index: number, groupBlockId?: string) => {
      dispatch(
        removeIncompleteCategorization({
          categorizationId,
          groupBlockId,
          index,
        })
      );
    },
    [dispatch]
  );

  const updateDocumentCategoryAnnotationAsync =
    (payload: DocumentCategoryAnnotation) => (dispatch: any) => {
      return new Promise<void>((resolve) => {
        dispatch(updateDocumentCategoryAnnotation(payload));
        resolve();
      });
    };

  const handleChange = useCallback(
    async (key: string, callback: () => void) => {
      if (!document?.id) {
        return;
      }

      const found = options.find((o) => o.key === key);

      dispatch(changeHasBeenEdited(true));
      if (found) {
        const isFixedType =
          categorization.setType?.toString() === CategorizationSetType.FIXED;

        let categoryId;
        let categoryValue;
        let categoryVisibleValue;

        if (isFixedType) {
          categoryId = found.value;
        } else {
          categoryId = categorization.categories[0].id;
          categoryValue = found.value;
          categoryVisibleValue = found.visibleValue;
        }

        const originalIndex = findOriginalIndex(
          annotations,
          documentCategoryAnnotations,
          groupBlockId,
          index
        );

        await dispatch(
          updateDocumentCategoryAnnotationAsync({
            documentId: document.id,
            categorizationId: id,
            categoryId: categoryId,
            categoryValue: categoryValue,
            categoryVisibleValue: categoryVisibleValue,
            groupBlockId,
            index,
            originalIndex,
            isByUser: true,
          })
        );

        removeIncompleteCategorizationInRedux(id, index, groupBlockId);

        normalizeEntityAnnotations(
          categorization,
          found.value,
          index,
          !!groupBlockId
        ).catch(() => undefined);

        callback();
        return;
      }

      dispatch(
        removeDocumentCategoryAnnotation({
          documentId: document.id,
          categorizationId: id,
          groupBlockId,
          index,
        })
      );
      callback();
    },
    [
      document?.id,
      id,
      options,
      groupBlockId,
      index,
      dispatch,
      normalizeEntityAnnotations,
      categorization,
      removeIncompleteCategorizationInRedux,
      annotations,
      documentCategoryAnnotations,
    ]
  );

  const handleTypingChange = useCallback(() => {
    if (!disableTyping) {
      dispatch(changeEditing(tabId));
      dispatch(changeActiveEntity(undefined));
      dispatch(changeAnnotating(false));
      dispatch(changeActiveButton({ id: tabId }));
      setDisableTyping(true);
      return;
    }

    dispatch(changeEditing(null));
    dispatch(changeActiveButton(undefined));
    setDisableTyping(false);
  }, [dispatch, disableTyping, tabId]);

  const handleHttpRequest = useCallback(
    async (signal: AbortSignal) => {
      if (!search) {
        return;
      }

      try {
        const { data } = await fetchClassifierOptions(id, signal);
        setOptions(data);
      } catch (e) {
        if (signal.aborted) {
          return;
        }

        console.log(e);
      }
    },
    [id, search]
  );

  const handleSearch = useCallback(
    async (value: string) => {
      if (!search) {
        return;
      }

      if (search.prefetch) {
        return;
      }

      if (value.length < 3) {
        return;
      }

      try {
        const input_ids = activeDocumentSet?.input
          ? [activeDocumentSet.input.id]
          : undefined;
        const { search } = categorization;

        const searchQuery = buildSearchQuery(
          search,
          value,
          null,
          language,
          input_ids,
          attributeFilter,
          user?.organization?.id
        );
        const { data } = await fetchClassifierOptionsSearch(
          search.endpoint,
          searchQuery
        );
        setOptions(data);
      } catch (e) {
        console.log(e);
      }
    },
    [
      search,
      categorization,
      language,
      attributeFilter,
      activeDocumentSet?.input,
      user?.organization?.id,
    ]
  );

  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, 500),
    [handleSearch]
  );

  const handleFilter = useCallback(
    (input: string, option: any) => {
      if (setType === CategorizationSetType.DYNAMIC) {
        return true;
      }

      return option.label.toLowerCase().includes(input.toLowerCase());
    },
    [setType]
  );

  const status = useMemo((): InputStatus => {
    if (
      activeDocumentSet?.status === Status.ExportFailed &&
      !selectedCategory?.value &&
      required
    ) {
      return "error";
    }
    return "";
  }, [activeDocumentSet?.status, selectedCategory, required]);

  useEffect(() => {
    if (!search?.prefetch) {
      return;
    }

    if (initialOptions?.length) {
      return;
    }

    const controller = new AbortController();

    handleHttpRequest(controller.signal);

    return () => {
      controller.abort();
    };
  }, [handleHttpRequest, initialOptions, search]);

  useEffect(() => {
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [debouncedHandleSearch]);

  const isIncomplete = useMemo(() => {
    const found = incompleteCategorizations.find((bc) => {
      return (
        bc.categorizationId === categorization.id &&
        bc.index === index &&
        bc.groupBlockId === groupBlockId
      );
    });

    return !!found;
  }, [incompleteCategorizations, categorization, index, groupBlockId]);

  const categorizationOptions = useMemo(() => {
    if (categorization.setType === CategorizationSetType.DERIVED) {
      return derivedCategorizationOptions[categorization.id] ?? [];
    }
    return options;
  }, [derivedCategorizationOptions, categorization, options]);

  const isOpen = useMemo((): boolean => {
    if (!editing) {
      return false;
    }

    if (!disableTyping) {
      return false;
    }

    return editing === tabId;
  }, [editing, tabId, disableTyping]);

  return {
    tabId,
    selectedCategory,
    categorizationOptions,
    handleChange,
    handleTypingChange,
    handleSearch: debouncedHandleSearch,
    handleFilter,
    disableTyping,
    status,
    isCategorizationDisabledByParent,
    disabledParentTooltip,
    isIncomplete,
    callEvents,
    isCategorizationLoading,
    isOpen,
  };
};

export default useCategorization;
