import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../app";

const useOrganizationAvatar = () => {
  const { user } = useAppSelector((state) => state.userReducer);
  const [image, setImage] = useState("");

  const loadImage = useCallback(
    async (controller: AbortController): Promise<void> => {
      try {
        if (
          user?.organization.branding.logoUrl &&
          user.organization.name.toLowerCase() !== "klassifai"
        ) {
          setImage(user?.organization.branding.logoUrl);
          return;
        }

        const file = await import("../../../assets/klassifaiWhiteIcon.svg");
        const response = await fetch(file.default, {
          signal: controller.signal,
        });
        const data = response.url;
        setImage(data);
      } catch (e) {
        console.log(e);
      }
    },
    [user?.organization]
  );

  useEffect(() => {
    if (!user?.organization?.name) {
      return;
    }

    let controller = new AbortController();
    loadImage(controller);

    return () => {
      controller.abort();
    };
  }, [user, loadImage]);

  return image;
};

export default useOrganizationAvatar;
