export const convertArrayBufferToImageUrl = (buffer: ArrayBuffer): string => {
  const blob = new Blob([buffer]);
  return URL.createObjectURL(blob);
};

export const getImageDimensions = (
  imageUrl: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = (err) => reject(err);
    img.src = imageUrl; // Set the source of the image
  });
};
