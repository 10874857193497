import React, { FC } from "react";
import useDocumentViewer from "../../hooks/useDocumentViewer";
import PDFSkeleton from "../../../app/pages/detailView/pdfSkeleton/PDFSkeleton";
import Annotator from "../../../annotatorLegacy/Annotator";
import "./LegacyPdfViewer.scss";
import useTextLayer from "../../hooks/useTextLayer";

const LegacyPdfViewer: FC = () => {
  const { documentData, loading } = useDocumentViewer();
  const { textLayerLoading } = useTextLayer();

  if (loading || textLayerLoading || !documentData) {
    return <PDFSkeleton />;
  }

  return (
    <div className="pdf-viewer__container-legacy">
      <Annotator data={documentData} />
    </div>
  );
};

export default LegacyPdfViewer;
