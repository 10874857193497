import React, { FC } from "react";
import Selection from "./selection/Selection";
import TableBuilder from "./tableBuilder/TableBuilder";
import AnnotationLayer from "./annotationLayer/AnnotationLayer";
import "./Page.scss";
import usePDFPage from "../../../document/hooks/usePDFPage";
import { useAppSelector } from "../../../app";
import pageForPageNumberSelector from "../../../annotation/selectors/pageForPageNumberSelector";
import useTrackPagesInView from "../../../document/hooks/useTrackPagesInView";
import useTrackCurrentPageNumber from "../../../document/hooks/useTrackCurrentPageNumber";
import BoundingBoxLayer from "./boundingBox/BoundingBoxLayer";

interface Props {
  page: number;
}

const Page: FC<Props> = ({ page }) => {
  const pageNumberRef = useTrackCurrentPageNumber(page);
  const { ref, inView, shouldFetchPageData } = useTrackPagesInView(page);
  const pdfPageData = useAppSelector((state) =>
    pageForPageNumberSelector(state, { page })
  );

  const { image } = usePDFPage(page, shouldFetchPageData);

  if (!pdfPageData) {
    return (
      <div className="page" ref={ref}>
        <div
          className="page__container"
          style={{
            width: "1000px",
            height: "1000px",
          }}
        >
          <div className="page__number_item" />
        </div>
      </div>
    );
  }

  const {
    dimension: { width, height },
  } = pdfPageData;

  if (!image) {
    return (
      <div className="page" ref={ref}>
        <div
          className="page__container"
          style={{
            width: `${width}px`,
            height: `${Math.max(height, 1000)}px`,
          }}
        >
          <div ref={pageNumberRef} className="page__number_item" />
          <AnnotationLayer page={page} pdfWidth={width} pdfHeight={height} />
        </div>
      </div>
    );
  }

  if (!inView) {
    return (
      <div className="page" ref={ref}>
        <div
          className="page__container"
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <div ref={pageNumberRef} className="page__number_item" />
          <img width={width} height={height} src={image} alt="PDF page" />
          <AnnotationLayer page={page} pdfWidth={width} pdfHeight={height} />
        </div>
      </div>
    );
  }

  return (
    <div className="page" ref={ref}>
      <div
        className="page__container"
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        <div ref={pageNumberRef} className="page__number_item" />
        <img
          width={width}
          height={height}
          src={image!}
          alt={`PDF page ${page}`}
        />
        <BoundingBoxLayer page={page} pdfWidth={width} pdfHeight={height} />
        <AnnotationLayer page={page} pdfWidth={width} pdfHeight={height} />
        <TableBuilder page={page} pdfWidth={width} pdfHeight={height} />
        <Selection width={width} height={height} pageNumber={page} />
      </div>
    </div>
  );
};

export default Page;
