import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { addPageToPDFData } from "../store/documentSlice";
import {
  convertArrayBufferToImageUrl,
  getImageDimensions,
} from "../../common/utilities/image";
import {
  useLazyFetchPdfPageImageQuery,
  useLazyFetchPdfPageMetaDataQuery,
  useLazyFetchPdfTextQuery,
} from "../store/documentApi";
import { calculateScalingFactor } from "../../annotation/utils/scaling";
// @ts-ignore
import { inflate } from "pako";
import { IPdfPageMetadataResponse } from "../interfaces/request";
import pdfPageImageSelector from "../selectors/pdfPageImageSelector";

const usePDFPage = (page: number, fetchData: boolean) => {
  const dispatch = useAppDispatch();

  const documentId = useAppSelector(
    (state) => state.documentReducer.activeDocument?.id
  );

  const image = useAppSelector((state) => pdfPageImageSelector(state, page));

  const [fetchPdfPageImage, { isFetching: imageLoading, isError: imageError }] =
    useLazyFetchPdfPageImageQuery();
  const [
    fetchPdfPageMetaData,
    { isFetching: metadataLoading, isError: metadataError },
  ] = useLazyFetchPdfPageMetaDataQuery();

  const [fetchPdfText, { isFetching: pdfTextLoading, isError: pdfTextError }] =
    useLazyFetchPdfTextQuery();

  const loading = useMemo(
    () => imageLoading || metadataLoading || pdfTextLoading,
    [imageLoading, metadataLoading, pdfTextLoading]
  );
  const error = useMemo(
    () => imageError || metadataError || pdfTextError,
    [imageError, metadataError, pdfTextError]
  );

  const loadPdfPageAndData = useCallback(
    async (fetchData: boolean) => {
      if (!documentId) {
        return;
      }

      if (!fetchData) {
        return;
      }

      try {
        const imageBuffer = await fetchPdfPageImage({
          documentId,
          page,
        }).unwrap();
        const image = convertArrayBufferToImageUrl(imageBuffer);
        const { width: imgWidth, height: imgHeight } = await getImageDimensions(
          image
        );

        const [imgWidthScalingFactor, imgHeightScalingFactor] =
          calculateScalingFactor(imgWidth, imgHeight);

        dispatch(
          addPageToPDFData({
            id: "",
            image,
            page,
            dimension: {
              unit: "pixels",
              width: imgWidth / imgWidthScalingFactor,
              height: imgHeight / imgHeightScalingFactor,
            },
            tokens: [],
            text: "",
          })
        );

        const [pdfMetaDataBuffer, textBuffer] = await Promise.all([
          fetchPdfPageMetaData({ documentId, page }).unwrap(),
          fetchPdfText({ documentId, page }).unwrap(),
        ]);

        const pdfMetaData: IPdfPageMetadataResponse = JSON.parse(
          inflate(pdfMetaDataBuffer, { to: "string" })
        );

        const { width, height } = pdfMetaData.dimension;
        const [widthScalingFactor, heightScalingFactor] =
          calculateScalingFactor(width, height);
        const pdfText = inflate(textBuffer, { to: "string" });

        dispatch(
          addPageToPDFData({
            id: "",
            image,
            page,
            dimension: {
              unit: pdfMetaData.dimension.unit,
              width: width / widthScalingFactor,
              height: height / heightScalingFactor,
            },
            tokens: pdfMetaData.tokens.map((t, index) => ({
              ...t,
              dataI: index,
              left: t.left / widthScalingFactor,
              width: t.width / widthScalingFactor,
              top: t.top / heightScalingFactor,
              height: t.height / heightScalingFactor,
            })),
            text: pdfText,
          })
        );
      } catch (e) {
        console.log(e);
      }
    },
    [
      documentId,
      fetchPdfPageMetaData,
      page,
      fetchPdfPageImage,
      fetchPdfText,
      dispatch,
    ]
  );

  useEffect(() => {
    loadPdfPageAndData(fetchData);
  }, [loadPdfPageAndData, fetchData]);

  return { image, loading, error };
};

export default usePDFPage;
