import React, { FC } from "react";
import { List, Space } from "antd";
import MultipleAnnotations from "./MultipleAnnotations";
import SingleAnnotation from "./SingleAnnotation";
import classNames from "classnames";
import EntityHotKey from "./EntityHotkey";
import { primaryColor } from "../../../common/utilities/color";
import HoverButtonsWrapper from "./HoverButtons/HoverButtonsWrapper";
import { GroupBlockEntityType } from "../../";
import useActiveDocument from "../../../document/hooks/useActiveDocument";
import useEntity from "../../hooks/useEntity";
import EntityPercentage from "./EntityPercentage";
import useEntityParentNormalization from "../../hooks/useEntityParentNormalization";
import EntityTitle from "./EntityTitle";

type EntityProps = {
  groupBlockEntityType: GroupBlockEntityType;
  groupBlockIndex: number;
  disabled: boolean;
  requiresAttention: boolean;
};

const Entity: FC<EntityProps> = ({
  groupBlockEntityType,
  groupBlockIndex,
  disabled,
  requiresAttention,
}) => {
  const { color } = groupBlockEntityType;

  const {
    annotationsForEntity,
    annotationForEntity,
    removeAnnotation,
    editingKey,
    handleEntityClick,
    isEditing,
    isAnnotating,
    isAnnotationLoading,
  } = useEntity(groupBlockEntityType, groupBlockIndex, disabled, false);

  useEntityParentNormalization(
    groupBlockEntityType.children,
    annotationForEntity
  );

  const { activeDocument } = useActiveDocument();

  const listItemClassNames = classNames({
    "list-item": true,
    "is-editing": isEditing === editingKey,
    "to-many-annotations": annotationsForEntity?.length > 1,
    disabled,
  });

  return (
    <div className={`list-item-container ${disabled ? "disabled" : ""}`.trim()}>
      <List.Item className={listItemClassNames} onClick={handleEntityClick}>
        <List.Item.Meta
          className="list-item-meta"
          avatar={
            <EntityHotKey
              groupBlockEntityType={groupBlockEntityType}
              groupBlockIndex={groupBlockIndex}
              disabled={disabled}
            />
          }
          title={<EntityTitle groupBlockEntityType={groupBlockEntityType} />}
          description={
            annotationsForEntity.length <= 1 && (
              <SingleAnnotation
                documentId={activeDocument?.id}
                entityType={groupBlockEntityType}
                entityAnnotation={annotationForEntity}
                isEditing={isEditing === editingKey}
                isAnnotating={isAnnotating}
                groupBlockIndex={groupBlockIndex}
                disabled={disabled}
                requiresAttention={requiresAttention}
              />
            )
          }
        />
        <Space size={15} className="list-item-actions">
          <EntityPercentage
            requiresAttention={requiresAttention}
            isEditing={isEditing === editingKey}
            modelScore={annotationForEntity.modelScore}
          />
          <HoverButtonsWrapper
            annotationLoading={isAnnotationLoading}
            isEditing={isEditing === editingKey}
            annotationsForEntity={annotationsForEntity}
            removeAnnotation={removeAnnotation}
            disabled={disabled}
          />
        </Space>
      </List.Item>
      {annotationsForEntity.length > 1 && (
        <MultipleAnnotations
          requiresAttention={requiresAttention}
          entityAnnotations={(annotationsForEntity || []).map((annotation) => ({
            ...annotation,
            index: annotation.index!,
            pageTokenIndices: annotation.pageTokenIndices!,
            value: annotation.values?.join(" ") || "",
            modelScore: annotation.modelScore!,
            isByUser: annotation.isByUser!,
            isLoading: annotation.isLoading,
          }))}
          color={color || primaryColor}
          removeAnnotation={removeAnnotation}
          disabled={disabled}
          documentId={activeDocument?.id}
          entityType={groupBlockEntityType}
          isEditing={isEditing === editingKey}
          isAnnotating={isAnnotating}
          groupBlockIndex={groupBlockIndex}
          handleEntityClick={handleEntityClick}
        />
      )}
    </div>
  );
};

export default Entity;
