import React, { FC } from "react";
import Controls from "./components/controls/Controls";
import TableOverview from "./components/TableOverview";
import TempAnnotationOverview from "./components/TempAnnotationOverview";
import Pages from "./components/pages/Pages";
import useAutoScroll from "./hooks/useAutoScroll";
import "./Annotator.scss";

const Annotator: FC = () => {
  const autoScroll = useAutoScroll();

  return (
    <div className="pdf-viewer__container">
      <Controls />
      <div className="annotator-container">
        <div
          id="annotator-pages-container"
          className="annotator-pages-container"
          onMouseMove={autoScroll}
        >
          <div className="annotator-pages">
            <Pages />
            <TableOverview />
            <TempAnnotationOverview />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Annotator;
