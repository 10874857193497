import React, { FC, useCallback, useMemo, MouseEvent, useRef } from "react";
import { Avatar } from "antd";
import { GroupBlockEntityType } from "../../";
import {
  getAccessibleTextColor,
  primaryColor,
} from "../../../common/utilities/color";
import EditIcon from "../../../common/iconComponents/EditIcon";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  addHoveredEntities,
  changeActiveEntity,
  changeAnnotating,
  removedHoveredEntities,
} from "../../../app/store/appSlice";
import useScroll from "../../hooks/useScroll";
import isErrorDisabledSelector from "../../selectors/isErrorDisabledSelector";

type Props = {
  groupBlockEntityType: GroupBlockEntityType;
  groupBlockIndex: number;
  disabled: boolean;
};

const EntityHotKey: FC<Props> = ({
  groupBlockEntityType,
  groupBlockIndex,
  disabled,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useScroll({
    ref: scrollRef,
    groupBlockIndex,
    entity: groupBlockEntityType,
  });

  const dispatch = useAppDispatch();
  const { isAnnotating, isEditing, activeEntity } = useAppSelector(
    (state) => state.appReducer
  );

  const activeDocumentSet = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet
  );

  const { disabledEntities } = useAppSelector(
    (state) => state.configMapReducer
  );

  const isErrorDisabled = useAppSelector(isErrorDisabledSelector);

  const colorToUse = useMemo(() => {
    const { color } = groupBlockEntityType;
    if (!color) {
      return primaryColor;
    }

    if (
      isErrorDisabled ||
      disabledEntities.includes(groupBlockEntityType.entityType.id)
    ) {
      return "#bebebe";
    }

    if (activeEntity && isAnnotating) {
      if (
        activeEntity.entityType.id === groupBlockEntityType.entityType.id &&
        groupBlockIndex === activeEntity.index
      ) {
        return color;
      } else {
        return "#bebebe";
      }
    }

    return color;
  }, [
    activeEntity,
    isAnnotating,
    disabledEntities,
    groupBlockEntityType,
    groupBlockIndex,
    isErrorDisabled,
  ]);

  const cursor = useMemo(() => {
    if (
      disabledEntities.includes(groupBlockEntityType.entityType.id) ||
      isErrorDisabled
    ) {
      return "not-allowed";
    }

    if (disabled) {
      return "default";
    }

    return "pointer";
  }, [
    disabled,
    disabledEntities,
    groupBlockEntityType.entityType.id,
    isErrorDisabled,
  ]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (!activeDocumentSet) {
        return;
      }

      if (isEditing) {
        return;
      }

      if (disabled) {
        return;
      }

      if (isErrorDisabled) {
        return;
      }

      if (disabledEntities.includes(groupBlockEntityType.entityType.id)) {
        return;
      }

      if (
        isAnnotating &&
        activeEntity?.entityType.id === groupBlockEntityType.entityType.id &&
        activeEntity?.index === groupBlockIndex
      ) {
        dispatch(changeAnnotating(false));
        dispatch(changeActiveEntity(undefined));
      } else {
        dispatch(changeAnnotating(true));
        dispatch(
          changeActiveEntity({
            ...groupBlockEntityType,
            index: groupBlockIndex,
          })
        );
      }
    },
    [
      disabled,
      activeDocumentSet,
      disabledEntities,
      isEditing,
      isAnnotating,
      activeEntity,
      groupBlockEntityType,
      groupBlockIndex,
      dispatch,
      isErrorDisabled,
    ]
  );

  const handleHoverAction = useCallback(
    (enter: boolean) => {
      if (activeEntity) {
        return;
      }

      if (enter) {
        dispatch(
          addHoveredEntities([
            { id: groupBlockEntityType.entityType.id, index: groupBlockIndex },
          ])
        );
      } else {
        dispatch(
          removedHoveredEntities([
            { id: groupBlockEntityType.entityType.id, index: groupBlockIndex },
          ])
        );
      }
    },
    [
      dispatch,
      groupBlockIndex,
      groupBlockEntityType.entityType.id,
      activeEntity,
    ]
  );

  if (groupBlockEntityType.hotkey) {
    return (
      <div
        id={`${groupBlockEntityType.entityType.id}-${groupBlockIndex}`}
        ref={scrollRef}
        onClick={handleClick}
        onMouseEnter={() => handleHoverAction(true)}
        onMouseLeave={() => handleHoverAction(false)}
      >
        <Avatar
          size={55}
          style={{
            backgroundColor: colorToUse,
            color: getAccessibleTextColor(colorToUse),
            width: 35,
            cursor,
          }}
          shape="square"
        >
          {groupBlockEntityType.hotkey.toUpperCase()}
        </Avatar>
      </div>
    );
  }

  return (
    <Avatar
      size={55}
      style={{
        backgroundColor: colorToUse,
        width: 35,
      }}
      shape="square"
      icon={<EditIcon fill={getAccessibleTextColor(colorToUse)} />}
    />
  );
};

export default EntityHotKey;
