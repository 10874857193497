import { GroupBlockEntityType } from "../../configMap";
import { primaryColor } from "../../common/utilities/color";
import { Annotation } from "../../annotator/interfaces/annotation";
import { AnnotationState } from "../store/annotationSlice";
import {
  ControlsAnnotationItems,
  ControlsAnnotations,
} from "../interfaces/annotation";
import { EAnnotatorMode } from "../constants";
import { ITextVertex } from "../../annotator/interfaces/textLayer";
import { AnnotationItem } from "../../documentTypes/interfaces";

export const getAnnotationForId = (
  annotationId: string,
  groupBlockIndex: number,
  annotations: Array<Annotation>,
  entity: GroupBlockEntityType
): Annotation | undefined => {
  if (!annotationId) {
    const id = `temp_${crypto.randomUUID()}`;

    return {
      id,
      page: 1,
      entity: {
        id: entity.entityType.id,
        name: entity.entityType.id.toString(),
        color: entity.color || primaryColor,
        entityType: "NER",
        entityNormalizations: entity.entityType.entityNormalizations,
        multipleGroupBlocks: entity.multipleGroupBlocks,
      },
      modelScore: 100,
      index: groupBlockIndex,
      isByUser: false,
      values: [],
      pageTokenIndices: [],
      isOutput: false,
      isLoading: false,
      multipleGroupBlocks: entity.multipleGroupBlocks,
    };
  }

  return annotations.find(
    (annotation) =>
      annotation.id === annotationId && annotation.index === groupBlockIndex
  );
};

export const groupAnnotations = (annotations: Array<Annotation>) =>
  annotations
    // Sort gives isOutput true priority
    .sort((a, b) => (b.isOutput ? 1 : a.isOutput ? -1 : 0))
    .reduce((result, annotation) => {
      const key = `${annotation.entity.id}-${annotation.index}`;
      (result[key] = result[key] || []).push(annotation);
      return result;
    }, {} as Record<string, Array<Annotation>>);

export const flattenGroupedOutput = (
  grouped: Record<string, Array<Annotation>>
): Array<Annotation> =>
  Object.values(grouped)
    .map((annotations) =>
      annotations.map((annotation, index) => {
        const isOutput = index === 0;
        return { ...annotation, isOutput };
      })
    )
    .flat();

export const getSideAndTableControlAnnotations = (
  annotations: Array<Annotation>
): ControlsAnnotations => {
  return annotations.reduce(
    (acc: ControlsAnnotations, annotation: Annotation) => {
      if (annotation.multipleGroupBlocks) {
        acc.tableControlAnnotations.push(annotation);
      } else {
        acc.sideControlAnnotations.push(annotation);
      }
      return acc;
    },
    { sideControlAnnotations: [], tableControlAnnotations: [] }
  );
};

export const getSideAndTableControlAnnotationItems = (
  annotations: Array<AnnotationItem>
): ControlsAnnotationItems => {
  return annotations.reduce(
    (acc: ControlsAnnotationItems, annotation: AnnotationItem) => {
      if (
        (annotation.isEntity && annotation.multipleGroupBlocks) ||
        (!annotation.isEntity && annotation.groupBlockId)
      ) {
        acc.tableControlAnnotations.push(annotation);
      } else {
        acc.sideControlAnnotations.push(annotation);
      }
      return acc;
    },
    { sideControlAnnotations: [], tableControlAnnotations: [] }
  );
};

export const getAnnotationsInReducer = (
  state: AnnotationState,
  multipleGroupBlocks: boolean
) => {
  if (multipleGroupBlocks) {
    return state.tableControlAnnotations;
  }

  return state.sideControlAnnotations;
};

export const mapAnnotatorModeToClassName = (
  annotatorMode: EAnnotatorMode
): string => {
  switch (annotatorMode) {
    case EAnnotatorMode.TABLE_ANNOTATION_MODE:
    case EAnnotatorMode.TABLE_CREATION_MODE:
    case EAnnotatorMode.ANNOTATION_MODE:
      return "annotating-mode";
    case EAnnotatorMode.TEXT_SELECTION_MODE:
      return "normal-mode";
  }
};

export const getAvailableTokens = (
  tokens: Array<ITextVertex>,
  annotations: Array<Annotation>,
  page: number
) => {
  const tokensInUse = annotations
    .filter((annotation) => annotation.page === page)
    .flatMap((annotation) => annotation.pageTokenIndices);

  if (!tokensInUse?.length) {
    return tokens;
  }

  return tokens.filter((token) => !tokensInUse.includes(token.dataI));
};
