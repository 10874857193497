import { createSelector } from "@reduxjs/toolkit";
import { Status } from "../../common/status/status";
import { RootState } from "../../app";

const getActiveDocumentSet = (state: RootState) =>
  state.documentSetsReducer.activeDocumentSet;
const getPdf = (state: RootState) => state.documentReducer.pdf;

const isErrorDisabledSelector = createSelector(
  [getActiveDocumentSet, getPdf],
  (activeDocumentSet, pdf) => {
    if (activeDocumentSet?.status === Status.Error) {
      const flatTokens = pdf?.pages.flatMap((page) => page.tokens);
      return (
        (pdf?.metadata?.pageCount || 0) <= 0 && (flatTokens?.length || 0) <= 0
      );
    }

    return false;
  }
);

export default isErrorDisabledSelector;
