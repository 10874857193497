import { KeyByAny } from "../../documentSet/interfaces/overview";
import { OptionType } from "./classifier";

export interface CategorizationEvent {
  triggerType: EventTriggerType;
  triggerOnLoad?: boolean;
  request: EventRequest;
  responseProperties: Array<EventCategorizationResponse>;
}

export interface EventRequest {
  endpoint: string;
  httpMethod: EventHttpMethod;
  urlParam?: EventParam;
  queryParams?: Array<EventParam>;
  bodyParams?: Array<EventParam>;
  responseProperties: Array<EventCategorizationResponse>;
}

export interface EventParam {
  type: EventType;
  id?: string;
  property?: string;
  separator?: string;
  index?: number;
  value?: string;
}

export interface EventCategorizationResponse {
  property?: string;
  propertyList?: string;
  type: EventType;
  categorizationId: string;
}

export enum EventTriggerType {
  ON_SELECT = "ON_SELECT",
  ON_FOCUS = "ON_FOCUS",
  ON_CLICK = "ON_CLICK",
}

export enum EventHttpMethod {
  GET = "GET",
  POST = "POST",
}

export enum EventType {
  CATEGORIZATION = "CATEGORIZATION",
  ENTITY = "ENTITY",
  INPUT_ATTRIBUTE = "INPUT_ATTRIBUTE",
  FIXED = "FIXED",
}

export interface EventBody {
  endpoint: string;
  httpMethod: string;
  bodyParams?: KeyByAny;
}

export interface KeyByOptions {
  [key: string]: Array<OptionType>;
}

export interface DerivedCategorizationOptionsPayload {
  categorizationId: string;
  options: Array<OptionType>;
}
