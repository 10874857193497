import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import useActiveDocument from "../../document/hooks/useActiveDocument";
import { useMultipleGroupBlocks } from "../../configMap";
import { EMPTY_ARRAY } from "../../annotation/selectors/annotationsForEntitySelector";
import useDocumentTypes from "./useDocumentTypes";
import {
  configMapIsLoading,
  setConfigMap,
} from "../../configMap/store/configMapSlice";
import { useUpdateDocumentTypeRequestMutation } from "../store/documentTypeApi";
import {
  setDocumentCategoryAnnotationsBatch,
  setIncompleteCategorizations,
} from "../../metadata/store/metadataSlice";
import { mapAnnotationsToAnnotatorFormat } from "../../annotation";
import {
  getSideAndTableControlAnnotationItems,
  getSideAndTableControlAnnotations,
} from "../../annotation/utils/utils";
import { AnnotationItem, DocumentTypeWithAnnotations } from "../interfaces";
import { cleanUpTableAnnotationIndexGaps } from "../../annotation/utils/annotationMappers";
import { setAnnotations } from "../../annotation/store/annotationSlice";
import {
  filterNewAnnotations,
  filterNewCategoryAnnotations,
  getCategorizationIds,
  getEntityIds,
  mapBackToDocumentCategoryAnnotations,
  mapBackToEntityAnnotations,
  mergeNewAnnotations,
  updateAnnotationsWithNewGroupBlockId,
  updateDcaWithNewGroupBlockId,
} from "../utils/synchronizeAnnotationHelper";
import { DocumentCategoryAnnotation } from "../../configMap/interfaces/category";
import { Annotation } from "../../annotator/interfaces/annotation";

const useSelectDocumentType = () => {
  const dispatch = useAppDispatch();

  const { activeDocument, activeDocumentMinimal, setActiveDocument } =
    useActiveDocument();

  const { setInitialMultipleGroupBlocksCount } = useMultipleGroupBlocks();

  const documentCategoryAnnotations = useAppSelector(
    (state) =>
      state.metadataReducer.categorizationState.documentCategoryAnnotations
  );

  const currentConfig = useAppSelector(
    (state) => state.configMapReducer.configMap
  );

  const annotations = useAppSelector((state) => {
    if (!activeDocument) {
      return EMPTY_ARRAY;
    }

    return state.annotationReducer.tableControlAnnotations.concat(
      state.annotationReducer.sideControlAnnotations
    );
  });

  const activeDocumentTypeId = useMemo(() => {
    return activeDocumentMinimal?.documentType?.id;
  }, [activeDocumentMinimal]);

  const { documentTypes } = useDocumentTypes();

  const [updateDocumentTypeRequest] = useUpdateDocumentTypeRequestMutation();

  const synchronizeAnnotations = useCallback(
    (documentType: DocumentTypeWithAnnotations) => {
      const configMap = documentType?.configMap;
      const newGroupBlocks = configMap?.groupBlocks;
      const oldGroupBlocks = currentConfig?.groupBlocks;

      const flatCategoryAnnotations:
        | Array<DocumentCategoryAnnotation>
        | undefined = documentType.documentCategoryAnnotations?.map((dca) => ({
        ...dca,
        groupBlockId: dca.groupBlockId ?? undefined,
        index: dca.index,
        originalIndex: dca.index,
      }));

      const mappedAnnotations = mapAnnotationsToAnnotatorFormat(
        documentType.entityAnnotations,
        configMap
      );

      const newEntityIds = getEntityIds(newGroupBlocks);
      const oldEntityIds = getEntityIds(oldGroupBlocks);
      const newCategorizationIds = getCategorizationIds(
        newGroupBlocks,
        configMap
      );
      const oldCategorizationIds = getCategorizationIds(
        oldGroupBlocks,
        currentConfig
      );

      const onlyNewAnnotations = filterNewAnnotations(
        mappedAnnotations,
        newEntityIds,
        oldEntityIds
      );
      const onlyNewCategoryAnnotations = filterNewCategoryAnnotations(
        flatCategoryAnnotations,
        newCategorizationIds,
        oldCategorizationIds,
        newGroupBlocks
      );

      let updatedAnnotations = updateAnnotationsWithNewGroupBlockId(
        [...annotations],
        newGroupBlocks
      );
      let updatedCategorizations = updateDcaWithNewGroupBlockId(
        [...documentCategoryAnnotations],
        newGroupBlocks
      );

      let annotationItems: Array<AnnotationItem> = [
        ...(updatedAnnotations.map((a) => ({
          ...a,
          isEntity: true,
        })) as Array<AnnotationItem>),
        ...((updatedCategorizations?.map((a) => ({ ...a, isEntity: false })) ??
          []) as Array<AnnotationItem>),
      ];
      let onlyNewAnnotationItems: Array<AnnotationItem> = [
        ...(onlyNewAnnotations.map((a) => ({
          ...a,
          isEntity: true,
        })) as Array<AnnotationItem>),
        ...((onlyNewCategoryAnnotations?.map((a) => ({
          ...a,
          isEntity: false,
        })) ?? []) as Array<AnnotationItem>),
      ];

      annotationItems = mergeNewAnnotations(
        annotationItems,
        onlyNewAnnotationItems
      );

      // Filter annotations to retain only relevant ones from the new config map
      annotationItems = annotationItems.filter((annotation) => {
        if (annotation.isEntity) {
          return newEntityIds.includes(annotation.entity!.id);
        }
        return newCategorizationIds.includes(annotation.categorizationId!);
      });

      const { sideControlAnnotations, tableControlAnnotations } =
        getSideAndTableControlAnnotationItems(annotationItems);

      const annotationsResult = cleanUpTableAnnotationIndexGaps(
        tableControlAnnotations
      );

      return sideControlAnnotations.concat(annotationsResult);
    },
    [currentConfig, annotations, documentCategoryAnnotations]
  );

  const changeDocumentType = useCallback(
    async (documentTypeId: string) => {
      dispatch(configMapIsLoading());
      if (!activeDocumentMinimal?.id) {
        return;
      }

      try {
        const data = await updateDocumentTypeRequest({
          documentId: activeDocumentMinimal.id,
          documentTypeId,
        }).unwrap();

        const annotationsResult = synchronizeAnnotations(data);
        const entityAnnotations: Array<Annotation> =
          mapBackToEntityAnnotations(annotationsResult);
        const categoryAnnotations: Array<DocumentCategoryAnnotation> =
          mapBackToDocumentCategoryAnnotations(annotationsResult);

        const { sideControlAnnotations, tableControlAnnotations } =
          getSideAndTableControlAnnotations(entityAnnotations);

        setActiveDocument({ ...activeDocumentMinimal, documentType: data });

        dispatch(setConfigMap(data.configMap!));
        dispatch(setIncompleteCategorizations([]));
        dispatch(
          setAnnotations({ tableControlAnnotations, sideControlAnnotations })
        );
        dispatch(setDocumentCategoryAnnotationsBatch(categoryAnnotations));

        setInitialMultipleGroupBlocksCount(
          data.configMap!,
          sideControlAnnotations.concat(tableControlAnnotations),
          categoryAnnotations
        );
      } catch (e) {
        console.error(e);
      }
    },
    [
      activeDocumentMinimal,
      setActiveDocument,
      setInitialMultipleGroupBlocksCount,
      dispatch,
      updateDocumentTypeRequest,
      synchronizeAnnotations,
    ]
  );

  return { activeDocumentTypeId, changeDocumentType, documentTypes };
};

export default useSelectDocumentType;
