import React, { FC } from "react";
import { DEFAULT_PAGE_SIZE, PAGE_OFFSET } from "../../constants";
import { Pagination } from "antd";

type Props = {
  numberOfPages: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
};

const Pager: FC<Props> = ({ numberOfPages, currentPage, handlePageChange }) => {
  if (numberOfPages <= 1) {
    return <div className="overview__placeholder" />;
  }

  return (
    <div className="pagination__container" style={{ position: "relative" }}>
      <Pagination
        hideOnSinglePage
        showQuickJumper
        pageSize={DEFAULT_PAGE_SIZE}
        showSizeChanger={false}
        current={currentPage + PAGE_OFFSET}
        total={numberOfPages * DEFAULT_PAGE_SIZE}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default Pager;
