import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import { ITextVertex } from "../interfaces/textLayer";

type Props = {
  pageNumber: number;
};

const selectPagesInView = (state: RootState) =>
  state.documentReducer.pagesInView;
const selectPdf = (state: RootState) => state.documentReducer.pdf;
const getPageNumber = (_: RootState, props: Props) => props.pageNumber;

const tokensForPageSelector = createSelector(
  [selectPagesInView, selectPdf, getPageNumber],
  (pagesInView, pdf, pageNumber): Array<ITextVertex> => {
    if (!pdf) {
      return [];
    }

    const { pages } = pdf;
    const foundPage = pages.find((page) => page.page === pageNumber);
    if (!foundPage) {
      return [];
    }

    const inView =
      pagesInView.find((piv) => piv.page === pageNumber)?.inView || false;

    if (!inView) {
      return [];
    }

    return foundPage.tokens;
  }
);

export default tokensForPageSelector;
