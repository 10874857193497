import { EntityAnnotation } from "..";
import {
  ConfigMap,
  getFlatEntityListFromConfigMap,
  GroupBlockEntityType,
} from "../../configMap";
import { Annotation } from "../../annotator/interfaces/annotation";
import { primaryColor } from "../../common/utilities/color";
import { EntityNormalization } from "../../annotator/interfaces/entity";
import { EntityAnnotationDto } from "../interfaces/annotation";
import { flattenGroupedOutput, groupAnnotations } from "./utils";
import { AnnotationItem } from "../../documentTypes/interfaces";

const createAnnotation = (
  entity: GroupBlockEntityType,
  entityAnnotation: EntityAnnotation,
  primaryColor: string
): Annotation => ({
  id: entityAnnotation.id!,
  page: entityAnnotation.page,
  entity: {
    id: entity.entityType.id,
    name: entity.entityType.id.toString(),
    color: entity.color || primaryColor,
    entityType: "NER",
    entityNormalizations: entity.entityType.entityNormalizations,
    multipleGroupBlocks: entity.multipleGroupBlocks,
  },
  values: entityAnnotation.value ? [entityAnnotation.value] : [],
  pageTokenIndices: entityAnnotation.pageTokenIndices,
  entityAnnotationNormalization: entityAnnotation.entityAnnotationNormalization,
  modelScore: entityAnnotation.modelScore,
  index: entityAnnotation.index,
  originalIndex: entityAnnotation.index,
  documentId: entityAnnotation.documentId,
  isByUser: entityAnnotation.isByUser,
  isOutput: entityAnnotation.isOutput || false,
  isLoading: false,
  multipleGroupBlocks: entity.multipleGroupBlocks,
  groupBlockId: entity.groupBlockId,
});

const findByID = (id: string, list: Array<GroupBlockEntityType>) =>
  list.find((entity) => entity.entityType.id === id);

const mapAnnotations = (
  annotations: Array<EntityAnnotation>,
  entityList: Array<GroupBlockEntityType>
): Array<Annotation> =>
  annotations
    .map((annotation) => {
      const entity = findByID(annotation.entityTypeId!, entityList);
      if (!entity) {
        return null;
      }
      return createAnnotation(entity, annotation, primaryColor);
    })
    .filter((annotation): annotation is Annotation => annotation !== null);

export const mapAnnotationsToAnnotatorFormat = (
  entityAnnotations?: Array<EntityAnnotation>,
  configMap?: ConfigMap
): Array<Annotation> => {
  if (!configMap || !entityAnnotations || !entityAnnotations?.length) {
    return [];
  }

  const entityList = getFlatEntityListFromConfigMap(configMap);
  if (!entityList.length) {
    return [];
  }

  const annotations = entityAnnotations || [];
  const mappedAnnotations = mapAnnotations(annotations, entityList);

  const groupedAnnotations = groupAnnotations(mappedAnnotations);
  return flattenGroupedOutput(groupedAnnotations);
};

export const mapToEntityAnnotationDto = (
  id: string,
  value: string,
  index: number,
  entityNormalizations: Array<EntityNormalization>,
  entityTypeId: string | undefined,
  multipleGroupBlocks: boolean,
  categoryValue?: string,
  parentValue?: string
): EntityAnnotationDto => {
  return {
    id: id,
    value: value,
    index: index,
    entityNormalizations: entityNormalizations,
    entityTypeId: entityTypeId,
    categoryValue: categoryValue,
    multipleGroupBlocks,
    parentValue: parentValue || "",
  };
};

export const cleanUpTableAnnotationIndexGaps = (
  tableControlAnnotations: Array<AnnotationItem>
): Array<AnnotationItem> => {
  const groupedAnnotations = groupAnnotationsByGroupBlockId(
    tableControlAnnotations
  );

  return processGroupedAnnotationsToRemoveEmptyIndexes(groupedAnnotations);
};

const groupAnnotationsByGroupBlockId = (
  annotations: Array<AnnotationItem>
): Record<string, Array<AnnotationItem>> => {
  return annotations.reduce(
    (acc: Record<string, Array<AnnotationItem>>, item) => {
      if (!item.groupBlockId) return acc;

      if (!acc[item.groupBlockId]) {
        acc[item.groupBlockId] = [];
      }
      acc[item.groupBlockId].push(item);

      return acc;
    },
    {}
  );
};

const processGroupedAnnotationsToRemoveEmptyIndexes = (
  groupedAnnotations: Record<string, Array<AnnotationItem>>
): Array<AnnotationItem> => {
  return Object.entries(groupedAnnotations).flatMap(([_, items]) => {
    return reassignIndexesToRemoveGaps(items);
  });
};

const reassignIndexesToRemoveGaps = (
  annotations: Array<AnnotationItem>
): Array<AnnotationItem> => {
  // Separate annotations based on the presence of originalIndex
  const [currentAnnotations, newAnnotations] =
    partitionAnnotations(annotations);

  const updatedCurrentAnnotations =
    reassignIndexesForCurrentAnnotations(currentAnnotations);

  const updatedNewAnnotations = reassignIndexesForNewAnnotations(
    newAnnotations,
    updatedCurrentAnnotations
  );

  return [...updatedCurrentAnnotations, ...updatedNewAnnotations];
};

const partitionAnnotations = (
  annotations: Array<AnnotationItem>
): [Array<AnnotationItem>, Array<AnnotationItem>] => {
  // Annotations without original index are added after the document set is loaded and in an empty row
  const currentAnnotations = annotations.filter((item) => item.originalIndex);
  const newAnnotations = annotations.filter((item) => !item.originalIndex);
  return [currentAnnotations, newAnnotations];
};

const reassignIndexesForCurrentAnnotations = (
  annotations: Array<AnnotationItem>
): Array<AnnotationItem> => {
  const uniqueOriginalIndexes = Array.from(
    new Set(annotations.map((item) => item.originalIndex))
  ).sort((a, b) => a! - b!);

  const indexMap: Record<number, number> = {};
  uniqueOriginalIndexes.forEach((oldIndex, newIndex) => {
    indexMap[oldIndex!] = newIndex + 1;
  });

  return annotations.map((item) => ({
    ...item,
    index: indexMap[item.originalIndex!],
  }));
};

const reassignIndexesForNewAnnotations = (
  newAnnotations: Array<AnnotationItem>,
  updatedNonEmptyItems: Array<AnnotationItem>
): Array<AnnotationItem> => {
  const groupedNewAnnotations = groupAnnotationsByIndex(newAnnotations);
  let nextIndex = updatedNonEmptyItems.length
    ? Math.max(...updatedNonEmptyItems.map((item) => item.index!)) + 1
    : 1;

  return Object.values(groupedNewAnnotations).flatMap((group) => {
    const updatedGroup = group.map((item) => ({
      ...item,
      index: nextIndex,
    }));
    nextIndex++; // Increment index for the next group
    return updatedGroup;
  });
};

const groupAnnotationsByIndex = (
  annotations: Array<AnnotationItem>
): Record<number, Array<AnnotationItem>> => {
  return annotations.reduce((acc, item) => {
    const key = item.index || 0;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as Record<number, Array<AnnotationItem>>);
};
