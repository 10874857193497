import { EntityAnnotation } from "../../annotation";
import { Annotation } from "../../annotator/interfaces/annotation";
import { ParentAnnotationsResponse } from "../selectors/entityParentAnnotationsSelector";
import { GroupBlockEntityType } from "../interfaces/entity";

export const getValueAndNormalizedValue = (
  entityAnnotation: EntityAnnotation
) => {
  const value = entityAnnotation?.value ?? "";
  const normalizedValue =
    entityAnnotation?.entityAnnotationNormalization?.normalizedValue;

  return {
    value,
    normalizedValue,
  };
};

export const calcAverageScore = (
  annotationsForEntity: Array<EntityAnnotation | Annotation>
): number => {
  const total = annotationsForEntity
    .map((a) => a.modelScore || 0)
    .reduce((acc, curr) => acc + curr, 0);

  return total / annotationsForEntity.length;
};

export const getParentAnnotationsForEntities = (
  annotations: Array<Annotation>,
  entities: Array<GroupBlockEntityType>
): Array<ParentAnnotationsResponse> | null => {
  const foundEntities = entities.filter((e) => e.children?.length);

  if (!foundEntities.length) {
    return null;
  }

  const foundEntityIds = foundEntities.map((fe) =>
    fe.entityType.id.toLowerCase()
  );

  const foundAnnotations = annotations.filter((a) =>
    foundEntityIds.includes(a.entity.id.toLowerCase())
  );

  if (!foundAnnotations.length) {
    return null;
  }

  return foundAnnotations.map((fa): ParentAnnotationsResponse => {
    const foundEntity = foundEntities.find(
      (fe) => fe.entityType.id.toLowerCase() === fa.entity.id.toLowerCase()
    )!;
    const childrenMapped = foundEntity.children?.map((c) =>
      entities
        .find((e) => e.id.toLowerCase() === c.id.toLowerCase())!
        .entityType.id.toLowerCase()
    );

    if (fa.entityAnnotationNormalization) {
      return {
        value: fa.values.join(" "),
        normalizedValue: {
          value: fa.entityAnnotationNormalization.normalizedValue,
          method: fa.entityAnnotationNormalization.normalizedMethod || "",
        },
        childrenIds: childrenMapped || [],
      };
    }

    return {
      value: fa.values.join(" "),
      childrenIds: childrenMapped || [],
    };
  });
};
