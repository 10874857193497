import { useAppDispatch, useAppSelector } from "../../app";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";
import { useCallback, useEffect, useRef } from "react";
import { mapToEntityAnnotationDto } from "../../annotation/utils/annotationMappers";
import http from "../../common/utilities/HttpModule";
import { EntityAnnotationBatchToBeNormalizedDto } from "../../annotation/interfaces/annotation";
import { NormalizedOutput } from "../interfaces/normalization";
import {
  addOrUpdateAnnotation,
  updateAnnotationNormalizationInState,
} from "../../annotation/store/annotationSlice";
// @ts-ignore
import hash from "object-hash";

const orgsToTrigger = ["klassifai", "topsfoods"];
const updateNormalizedEntities = ["topfoodsCartonsToUnits"];
const multiGroupBlock = [
  "87d805ed-0b85-4d7f-b0a5-fe101bb07392",
  "381fd87d-eb52-4f3f-ac9a-7e7f15e0e4d0",
];

const useTopsfoodsNormalizations = () => {
  const prevHashAnnotations = useRef("");
  const dispatch = useAppDispatch();

  const documentId = useAppSelector(
    (state) => state.documentReducer.activeDocument?.id
  );

  const allAnnotations = useAppSelector(selectAnnotationReducer);
  const metaData = useAppSelector(
    (state) => state.documentReducer.activeDocument?.metaData
  );

  const org = useAppSelector(
    (state) => state.userReducer.user?.organization.name
  );

  const runTopsFoodsNormalizations = useCallback(async () => {
    if (!org) {
      return;
    }

    if (!orgsToTrigger.includes(org?.toLowerCase())) {
      return;
    }

    if (!allAnnotations?.length) {
      return;
    }

    if (!metaData) {
      return;
    }

    const annotationsHash = hash(allAnnotations);

    if (prevHashAnnotations.current === annotationsHash) {
      return;
    }

    prevHashAnnotations.current = annotationsHash;

    const annotationsDto = allAnnotations.map((annotation) =>
      mapToEntityAnnotationDto(
        annotation.id,
        annotation.values.join(" "),
        annotation.index || 1,
        annotation.entity.entityNormalizations ?? [],
        annotation.entity.id,
        annotation.multipleGroupBlocks
      )
    );

    const entityAnnotationsBody: EntityAnnotationBatchToBeNormalizedDto = {
      entityAnnotations: annotationsDto,
      metaData: metaData,
    };

    const url = "/customer/normalization/api/v1/search/topsfoods";
    try {
      const { data } = await http.post<Array<NormalizedOutput>>(
        url,
        entityAnnotationsBody
      );

      data.forEach((d) => {
        if (updateNormalizedEntities.includes(d.normalizedMethod!)) {
          const annotation = allAnnotations.find((a) => a.id === d.id!);
          if (!annotation) {
            return;
          }

          dispatch(
            updateAnnotationNormalizationInState({
              id: d.id!,
              method: d.normalizedMethod,
              newValue: d.normalizedValue,
              multipleGroupBlocks: annotation.multipleGroupBlocks,
            })
          );
        } else {
          dispatch(
            addOrUpdateAnnotation({
              annotation: {
                documentId: documentId!,
                id: `temp_${crypto.randomUUID()}`,
                page: 1,
                values: d.normalizedValue.split(" "),
                pageTokenIndices: [],
                index: d.index! || 1,
                isOutput: true,
                multipleGroupBlocks: multiGroupBlock.includes(d.id!),
                entity: {
                  id: d.id!,
                  name: "",
                  color: "",
                  entityType: "NER",
                  multipleGroupBlocks: multiGroupBlock.includes(d.id!),
                },
                isByUser: false,
                isLoading: false,
                modelScore: 100,
              },
              documentId: documentId!,
              multipleGroupBlocks: multiGroupBlock.includes(d.id!),
            })
          );
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [org, allAnnotations, metaData, dispatch, documentId]);

  useEffect(() => {
    runTopsFoodsNormalizations();
  }, [runTopsFoodsNormalizations]);
};

export default useTopsfoodsNormalizations;
