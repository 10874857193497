import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../app";
import { changeJwtLoggedIn, logout } from "../store/userSlice";
import useIsAuthenticated from "./useIsAuthenticated";
import useAuthentication from "./useAuthentication";
import http from "../../common/utilities/HttpModule";
import useAuthenticationCookie from "./useAuthenticationCookie";
import { COOKIE_NAME } from "./useAuth0thenticate";

// @ts-ignore
const JWT_TOKEN = window.__JWT_TOKEN__;
const LOGOUT_URL = "https://klassifai.kyma.flexso.cloud/logout.html";

const useJwtAuthenticate = () => {
  const dispatch = useAppDispatch();

  const authenticateUser = useAuthentication();

  const { isAuthenticated } = useIsAuthenticated();
  const { removeCookie } = useAuthenticationCookie();

  const handleJwtTokenLogin = useCallback(
    async (jwt: string) => {
      if (!jwt?.startsWith("Bearer")) {
        return;
      }

      const jwt_to_use = JWT_TOKEN.replace("Bearer ", "").trim();

      if (isAuthenticated) {
        return;
      }

      if (jwt_to_use) {
        await authenticateUser(jwt_to_use);

        dispatch(changeJwtLoggedIn(true));
      }
    },
    [authenticateUser, isAuthenticated, dispatch]
  );

  const handleJwtLogout = useCallback(async () => {
    try {
      await http.get(LOGOUT_URL);
      removeCookie(COOKIE_NAME);
      dispatch(logout());
      dispatch(changeJwtLoggedIn(false));
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, removeCookie]);

  useEffect(() => {
    handleJwtTokenLogin(JWT_TOKEN);
  }, [handleJwtTokenLogin]);

  return handleJwtLogout;
};

export default useJwtAuthenticate;
