import { Annotation } from "../../annotator/interfaces/annotation";
import {
  Entity as EntityInterface,
  EntityNormalization,
} from "../../annotator/interfaces/entity";
import { MetaData } from "../../document/interfaces";
import { AnnotationItem } from "../../documentTypes/interfaces";

export interface DocumentAnnotationsState {
  [key: string]: Array<Annotation>;
}

export interface DocumentAnnotations {
  documentId: string;
  annotations: Array<Annotation>;
  multipleGroupBlocks?: boolean;
}

export interface DocumentAnnotation {
  documentId: string;
  annotation: Annotation;
  multipleGroupBlocks: boolean;
}

export interface UpdateAnnotation {
  annotation: Annotation;
  method: string;
  newValue: string;
  options?: Array<string>;
  errorMessage?: string;
  multipleGroupBlocks: boolean;
  isByUser?: boolean;
  errorType?: NormalizationErrorType;
}

export interface UpdateAnnotationNormalization {
  id: string;
  method: string | undefined;
  newValue: string;
  options?: Array<string>;
  errorMessage?: string;
  multipleGroupBlocks: boolean;
  isByUser?: boolean;
  errorType?: NormalizationErrorType;
}

export interface RemoveAnnotationPayload {
  documentId: string;
  annotationId: string;
  multipleGroupBlocks: boolean;
}

export interface RemoveAnnotationsForEntitiesPayload {
  entityIds: Array<string>;
  documentId: string;
  groupBlockIndex: number;
  isGroupRemoved?: boolean;
  multipleGroupBlocks: boolean;
}

export interface UpdateMultiGroupBlockValuePayload {
  entityIds: Array<string>;
  documentId: string;
  groupBlockIndex: number;
  addBefore?: boolean;
  multipleGroupBlocks: boolean;
}

export interface SetIsLoadingAnnotationPayload {
  id: string;
  isLoading: boolean;
  multipleGroupBlocks: boolean;
}

export interface SetIsLoadingAnnotationsPayload {
  ids: Array<string>;
  isLoading: boolean;
}

export interface EntityAnnotation {
  id?: string;
  page: number;
  index: number;
  originalIndex?: number;
  pageTokenIndices: Array<number>;
  value: string;
  modelScore: number;
  isByUser: boolean;
  entityTypeId?: string;
  entity?: EntityInterface;
  documentId?: string;
  entityAnnotationNormalization?: EntityAnnotationNormalization;
  isLoading?: boolean;
  isOutput?: boolean;
  multipleGroupBlocks?: boolean;
  groupBlockId?: string;
}

export interface EntityAnnotationNormalization {
  id?: string;
  normalizedMethod: string | undefined;
  normalizedValue: string;
  isByUser?: boolean;
  options?: Array<string>;
  errorMessage?: string;
  errorType?: NormalizationErrorType;
}

export interface EntityAnnotationToBeNormalizedDto {
  entityAnnotation: EntityAnnotationDto;
  metaData: MetaData | undefined;
}

export interface EntityAnnotationBatchToBeNormalizedDto {
  entityAnnotations: Array<EntityAnnotationDto>;
  metaData: MetaData | undefined;
}

export interface EntityAnnotationNormalizedDto {
  value: string;
  method: string;
}

export interface EntityAnnotationDto {
  id: string;
  value: string;
  normalizedValue?: EntityAnnotationNormalizedDto;
  index: number;
  entityNormalizations: Array<EntityNormalization>;
  entityTypeId?: string;
  categoryValue?: string;
  parentValue: string | null;
  multipleGroupBlocks: boolean;
  errorType?: NormalizationErrorType;
}

export interface ControlsAnnotations {
  sideControlAnnotations: Array<Annotation>;
  tableControlAnnotations: Array<Annotation>;
}

export interface ControlsAnnotationItems {
  sideControlAnnotations: Array<AnnotationItem>;
  tableControlAnnotations: Array<AnnotationItem>;
}

export interface HighlightedEntity {
  id: string;
  index: number;
}

export enum NormalizationErrorType {
  INVALID_VALUE = "INVALID_VALUE",
  ERROR_WHILE_NORMALIZING = "ERROR_WHILE_NORMALIZING",
}
