import { Document } from "../../document";
import { User } from "../../user";
import { Status } from "../../common/status/status";
import { Dayjs } from "dayjs";
import { KeyByAny } from "./overview";

export interface InputType {
  id: number;
  name: string;
}

export interface Input {
  id: string;
  displayName: string;
  legacyAnnotator: boolean;
  attribute: KeyByAny;
}

export interface EmailReply {
  replyText: string;
}

export interface Sender {
  address: string;
  name: string;
}

export interface Email {
  id: number;
  sender: Sender;
  emailReply?: EmailReply;
}

export interface LogWebhookRequest {
  id: number;
  responseBody: Generic;
  responseStatusCode: number;
  createdDate: Date;
}

export interface ItemBody {
  contentType: string;
  content: string;
}

export interface Generic {
  [key: string]: any;
}

export interface DocumentSetColumns {
  id: string;
  input: Input;
  name: string;
  modifiedDate: Dayjs;
  createdDate: Dayjs;
  approvedDate: Dayjs;
  documents?: Array<Document>;
  assignee?: User;
  approvedBy?: User;
  annotatedBy?: User;
  status: Status;
  email?: Email;
  metadata?: Generic;
  searchable: string;
  logWebhookRequests: Array<LogWebhookRequest>;
}

export interface DocumentSetData extends DocumentSetColumns {}

export type CounterState = {
  overview: number;
  error: number;
  remove: number;
};

export enum DocumentSetFetchingErrorType {
  INVALID_QUERY = "invalidQuery",
  OTHER_ERROR = "otherError",
}

export interface AdjacentDocumentSetIds {
  previousDocumentSetId: string;
  nextDocumentSetId: string;
}

export type NumberByKey = {
  [key: string]: number;
};
