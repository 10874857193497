import { ConfigMap, GroupBlockData, GroupBlockEntityType } from "../";
import http from "../../common/utilities/HttpModule";
import { TableInput } from "../../annotation/";
import { Annotation } from "../../annotator/interfaces/annotation";
import {
  DocumentCategoryAnnotation,
  DocumentCategoryAnnotationCreate,
} from "../interfaces/category";

export const getFlatEntityListFromConfigMap = (
  configMap: ConfigMap | undefined,
  filterRequired = false
): Array<GroupBlockEntityType> => {
  if (!configMap?.groupBlocks?.length) {
    return [];
  }

  return [...configMap.groupBlocks]
    .sort((a, b) => a.index - b.index)
    .map((block) => ({
      ...block,
      groupBlockEntityTypes: [...block.groupBlockEntityTypes!].sort(
        (a, b) => a.index - b.index
      ),
    }))
    .flatMap((block) =>
      block.groupBlockEntityTypes.map((entity) => ({
        ...entity,
        multipleGroupBlocks: block.multipleGroupBlocks,
        groupBlockId: block.id,
      }))
    )
    .filter((entity) => {
      if (filterRequired) {
        return entity.required;
      }

      return true;
    });
};

export const getFlatEntityListFromGroupBlocks = (
  groupBlocks: Array<GroupBlockData> | undefined,
  filterRequired = false
): Array<GroupBlockEntityType> => {
  if (!groupBlocks?.length) {
    return [];
  }

  return [...groupBlocks]
    .sort((a, b) => a.index - b.index)
    .map((block) => ({
      ...block,
      groupBlockEntityTypes: [...block.groupBlockEntityTypes!].sort(
        (a, b) => a.index - b.index
      ),
    }))
    .flatMap((block) =>
      block.groupBlockEntityTypes.map((entity) => ({
        ...entity,
        multipleGroupBlocks: block.multipleGroupBlocks,
        groupBlockId: block.id,
      }))
    )
    .filter((entity) => {
      if (filterRequired) {
        return entity.required;
      }

      return true;
    });
};

export const postData = (
  documentIds: Array<string>,
  tables: Array<TableInput> | null,
  annotations: Array<Annotation> | null,
  documentCategoryAnnotations: Array<DocumentCategoryAnnotation>
) => {
  return Promise.all(
    documentIds.map((dId) => {
      const tableData =
        tables?.filter((table) => table.documentId === dId) ?? [];

      const annotationMap =
        annotations?.filter((annotation) => annotation.documentId === dId) ??
        [];

      const annotationData = annotationMap
        .filter((annotation) => !annotation.tempAnnotation)
        .map((annotation) => ({
          id: annotation.id.includes("temp") ? null : annotation.id,
          entityTypeId: annotation.entity.id,
          page: annotation.page,
          pageTokenIndices: annotation.pageTokenIndices,
          value: annotation.values?.length ? annotation.values.join(" ") : null,
          modelScore: annotation.modelScore,
          index: annotation.index,
          documentId: dId,
          isByUser: annotation.isByUser,
          isOutput: annotation.isOutput,
          entityAnnotationNormalization:
            annotation.entityAnnotationNormalization,
        }));

      const documentCategoryAnnotationData: Array<DocumentCategoryAnnotationCreate> =
        documentCategoryAnnotations
          .filter((dca) => dca.documentId === dId)
          .map(
            (dca): DocumentCategoryAnnotationCreate => ({
              ...dca,
              groupBlockId: dca.groupBlockId ? dca.groupBlockId : null,
              index: dca.index ? dca.index : 0,
            })
          );

      const data = {
        tableAnnotations: tableData,
        entityAnnotations: annotationData,
        documentCategoryAnnotations: documentCategoryAnnotationData,
      };

      return http.post(`api/v1/documents/${dId}/batch`, data);
    })
  );
};
