import React, { FC, useMemo } from "react";
import useCategorization from "../../hooks/useCategorization";
import { useTranslation } from "react-i18next";
import { CategorizationWithConfig } from "../../../configMap/interfaces/category";
import CategorizationSwitch from "./CategorizationSwitch/CategorizationSwitch";

type Props = {
  disabled: boolean;
  categorizationWithConfig: CategorizationWithConfig;
  index: number;
  groupBlockId?: string;
};

const Categorization: FC<Props> = ({
  categorizationWithConfig,
  disabled,
  index,
  groupBlockId,
}) => {
  const { label } = categorizationWithConfig.categorization;

  const {
    i18n: { language },
  } = useTranslation();

  const {
    tabId,
    handleChange,
    selectedCategory,
    categorizationOptions,
    handleTypingChange,
    handleSearch,
    handleFilter,
    disableTyping,
    status,
    isCategorizationDisabledByParent,
    disabledParentTooltip,
    isIncomplete,
    callEvents,
    isCategorizationLoading,
    isOpen,
  } = useCategorization(categorizationWithConfig, index, groupBlockId);

  const className = useMemo(() => {
    if (disabled) {
      return "classifier-item__select-disabled";
    }

    let classes = "";
    if (isIncomplete) {
      classes += "classifier-item__select-is-incomplete";
    }
    if (isCategorizationDisabledByParent) {
      classes += " classifier-item__select-no-parent-value";
    }

    return classes;
  }, [disabled, isCategorizationDisabledByParent, isIncomplete]);

  return (
    <div className="classifier-item__container">
      <CategorizationSwitch
        categorizationWithConfig={categorizationWithConfig}
        label={label}
        language={language}
        selectedCategory={selectedCategory}
        isCategorizationDisabledByParent={isCategorizationDisabledByParent}
        disabledParentTooltip={disabledParentTooltip}
        disableTyping={disableTyping}
        disabled={disabled}
        isCategorizationLoading={isCategorizationLoading}
        categorizationOptions={categorizationOptions}
        callEvents={callEvents}
        handleChange={handleChange}
        handleTypingChange={handleTypingChange}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        status={status}
        tabId={tabId}
        className={className}
        isOpen={isOpen}
      />
    </div>
  );
};

export default Categorization;
