import {
  EventCategorizationResponse,
  EventParam,
  EventType,
} from "../interfaces/categorizationEvents";
import { KeyByAny } from "../../documentSet/interfaces/overview";
import {
  removeDocumentCategoryAnnotation,
  setDerivedCategorizationOptions,
  updateDocumentCategoryAnnotation,
} from "../store/metadataSlice";
import {
  DocumentCategoryAnnotation,
  ICategorization,
} from "../../configMap/interfaces/category";
import { Annotation } from "../../annotator/interfaces/annotation";

export const getDcaValue = (
  categorizationId: string,
  documentCategoryAnnotations: any[],
  urlParam: EventParam,
  value: string
) => {
  let dcaValue = value;
  if (categorizationId !== urlParam.id) {
    const dca = documentCategoryAnnotations.find(
      (dca) => dca.index === 1 && dca.categorizationId === urlParam.id
    );
    if (!dca?.categoryValue) {
      return;
    }
    dcaValue = dca.categoryValue;
  }

  const { separator, index } = urlParam || {};
  if (!separator || index === null) {
    return dcaValue;
  }

  const splittedValue = dcaValue.split(separator);
  return splittedValue.length <= index! ? null : splittedValue[index!].trim();
};

export const getEntityAnnotationValue = (
  entityAnnotations: any[],
  urlParam: EventParam
) => {
  const entityAnnotation = entityAnnotations.find(
    (ea) => ea.index === 1 && ea.entity.id === urlParam.id
  );

  if (!entityAnnotation) {
    return;
  }

  return (
    entityAnnotation.entityAnnotationNormalization?.normalizedValue ||
    entityAnnotation.values?.join(" ") ||
    ""
  );
};

export const getParamValue = (
  param: EventParam | undefined,
  value: string,
  categorizationId: string,
  documentCategoryAnnotations: any[],
  entityAnnotations: any[],
  attribute: KeyByAny | undefined
) => {
  if (!param) {
    return null;
  }

  switch (param.type) {
    case EventType.CATEGORIZATION:
      return getDcaValue(
        categorizationId,
        documentCategoryAnnotations,
        param,
        value
      );
    case EventType.ENTITY:
      return getEntityAnnotationValue(entityAnnotations, param);
    case EventType.INPUT_ATTRIBUTE:
      return attribute;
    case EventType.FIXED:
      return param.value ?? null;
    default:
      return null;
  }
};

export const createDcaFromResponse = (
  responseProperties: Array<EventCategorizationResponse>,
  response: any,
  dispatch: any,
  documentId: string,
  categoryId: string
) => {
  for (const responseProperty of responseProperties) {
    const { property, propertyList, categorizationId } = responseProperty;
    const content = !property ? response : response[property];

    if (!content) {
      dispatch(
        removeDocumentCategoryAnnotation({
          documentId,
          categorizationId,
          index: 1,
        })
      );

      if (propertyList && response[propertyList]) {
        dispatch(
          setDerivedCategorizationOptions({
            categorizationId,
            options: response[propertyList],
          })
        );
      }
      continue;
    }

    if (propertyList && response[propertyList]) {
      dispatch(
        setDerivedCategorizationOptions({
          categorizationId,
          options: response[propertyList],
        })
      );
    }

    const dca: DocumentCategoryAnnotation = {
      documentId,
      categorizationId,
      index: 1,
      categoryId,
      categoryValue: content.value,
      categoryVisibleValue: content.visibleValue,
    };

    dispatch(updateDocumentCategoryAnnotation(dca));
  }
};

export const getBodyParams = (
  params: Array<EventParam> | undefined,
  value: string,
  categorization: ICategorization,
  documentCategoryAnnotations: Array<DocumentCategoryAnnotation>,
  entityAnnotations: Array<Annotation>,
  attribute: KeyByAny | undefined
): KeyByAny => {
  const bodyParameters: KeyByAny = {};
  if (!params) {
    return bodyParameters;
  }

  for (const param of params) {
    if (!param?.property) {
      continue;
    }

    const paramValue = getParamValue(
      param,
      value,
      categorization.id,
      documentCategoryAnnotations,
      entityAnnotations,
      attribute
    );
    if (paramValue) {
      bodyParameters[param.property] = paramValue;
    }
  }

  return bodyParameters;
};

export const getQueryParams = (
  params: Array<EventParam> | undefined,
  value: string,
  categorization: ICategorization,
  documentCategoryAnnotations: Array<DocumentCategoryAnnotation>,
  entityAnnotations: Array<Annotation>,
  attribute: KeyByAny | undefined
): string => {
  if (!params) {
    return "";
  }

  const result = params
    .map((param) => {
      const paramValue = getParamValue(
        param,
        value,
        categorization.id,
        documentCategoryAnnotations,
        entityAnnotations,
        attribute
      );
      return paramValue !== null ? `${param.property}=${paramValue}` : null;
    })
    .filter((param) => param !== null)
    .join("&");
  return `?${result}`;
};

export const areResponseCategorizationsEmpty = (
  responseProperties: Array<EventCategorizationResponse>,
  documentCategoryAnnotations: Array<DocumentCategoryAnnotation>
) => {
  for (const responseProperty of responseProperties) {
    const { categorizationId } = responseProperty;
    const found = documentCategoryAnnotations.find(
      (dca) => dca.index === 1 && dca.categorizationId === categorizationId
    );
    if (found) {
      return false;
    }
  }

  return true;
};
